<template>
  <v-app>
    <div>
        <v-alert
          v-model="alert"
          border="left"
          dark
          dismissible
          class="alert"
          :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}"
        >
        {{alertMessage}}
        </v-alert>
    </div>
    <div class="row">
       <div class="col-md-10 ml-auto mr-auto">
          <div class="mb-8 mt-8 text-center">
            <h1>Pending Configure Allowances</h1>
          </div>
          <v-row>
            <v-col cols="4" class="ml-auto">
              <v-row no-gutters>
                <v-col cols="6">
                    <v-select v-model="action" class="pt-5" :items="actions" item-value="id" item-text="name" :disabled="selected.length === 0"   label="Actions" return-object outlined dense/>
                  </v-col>
                  <v-col cols="6 d-flex">
                    <div class="submit-button-container">
                      <Button
                      :label="'Submit'"
                      :btnType="'Submit'"
                      @onClick="changeAction"
                      class=""
                    />
                    </div>
                  </v-col>
              </v-row>
            </v-col>
          </v-row>
          <div>
            <div v-if="!pendingUnionJobtitleSalaries.length" class="text-center">
              <v-progress-circular indeterminate
                                   color="primary"></v-progress-circular>
            </div>
            <v-simple-table v-if="pendingUnionJobtitleSalaries.length > 0">
              <template>
                <thead>
                  <tr>
                    <th></th>
                    <th>
                      Union Membership
                    </th>
                    <th v-for="(item, index) in pendingUnionJobtitleSalaries[0].unionJobTitleListItems" :key="index">
                      {{item.salaryComponent}}
                    </th>
                    <th>
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item , index) in pendingUnionJobtitleSalaries" :key="index">
                    <td>
                      <input type="checkbox"
                             class="checkbox"
                             :value="{id:item.id}"
                             v-model="selected" />
                    </td>
                    <td>{{item.unionMembership}}</td>
                    <td v-for="(itemSub, index) in item.unionJobTitleListItems" :key="index">
                      {{itemSub.value}}
                    </td>
                    <td>
                      <div class="d-flex">
                        <Button :btnType="'Submit'" :label="'Approve'" @onClick="openConfiModal(item)" class="mr-2" />
                        <Button :btnType="'Cancel'" :label="'Reject'" @onClick="openCancelModal(item)" />
                      </div>
                    </td>
                  </tr>
                </tbody>
                <ConfirmationDialog :title="'Are you sure you want to submit?'" :isLoading="isLoading" :dialog="dialog" :btnTitle="'Yes'" @close="dialog = false" @btnAction="approve" />
              </template>
            </v-simple-table>
          </div>
       </div>

    </div>
  </v-app>
</template>
<script>
import { FETCH_PENDING_UNIONJOBTITLESALARIES, APPROVE_UNIONJOBTITLESALARIES } from '@/store/action-type'
import ConfirmationDialog from '@/components/ui/confirmationDialog.vue'
import Button from '@/components/ui/form/Button.vue'
export default {
  components: {
    Button,
    ConfirmationDialog
  },
  data () {
    return {
      alertMessage: '',
      alert: false,
      alertType: '',
      action: '',
      item: null,
      dialog: false,
      isLoading: false,
      selected: [],
      actions: [{ id: 1, name: 'Approve' }, { id: 2, name: 'Reject' }, { id: 3, name: 'Recycle' }]
    }
  },
  computed: {
    pendingUnionJobtitleSalaries () {
      return this.$store.getters.pendingUnionJobtitleSalaries
    }
  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
      setTimeout(() => {
        this.alert = false
      }, 5000)
    },
    approve () {
      this.isLoading = true
      this.$store.dispatch(APPROVE_UNIONJOBTITLESALARIES, { status: 1, ApprovalItems: [{ id: this.item.id }] }).then(() => {
        this.showAlertMessage('Union jobtitle salaries approval Successfully', 'success')
        this.isSaving = false
        this.$router.go()
      }).catch(() => {
        this.showAlertMessage('Union jobtitle salaries approval failed', 'error')
      }).finally(() => {
        this.dialog = false
        this.isLoading = false
        this.onReset()
      })
    },

    openConfiModal (item) {
      this.item = item
      this.dialog = true
    },
    openCancelModal (item) {

    },
    changeAction () {
      if (this.action.id === 1) {
        this.$store.dispatch(APPROVE_UNIONJOBTITLESALARIES, { status: 1, ApprovalItems: this.selected }).then(result => {
          this.showAlertMessage('Union jobtitle salaries approval Successfully', 'success')
        }).catch(() => {
          this.showAlertMessage('Union jobtitle salaries approval failed', 'error')
        })
      }
      this.$router.go()
    }
  },
  mounted () {
    this.$store.dispatch(FETCH_PENDING_UNIONJOBTITLESALARIES).then(() => {}).catch(error => {
      console.log(error)
      this.showAlertMessage('Uable to fetch pending Union jobtitle Salaries', 'error')
    })
  }
}
</script>
<style scoped>
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.actionBar {
  display: flex;
    align-items: center;
    height: 100%;
}
.actionBarContainer{
  width: 100%;
}
.submit-button-container {
  display: flex;
  align-items: center;
  padding-bottom: 5px;
}
</style>
